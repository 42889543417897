import React, { useState } from "react"
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';

function Layout({
  up,
  children,
  className = '',
  headerClassName = '',
  footerClassName = '',
}) {
  const [isExpanded, toggleExpansion] = useState(false)
  const { pathname } = useLocation();
  return (
    <div className={`max-w-6xl mx-auto px-4 ${headerClassName}`}>
      <header className="py-4 flex items-center justify-between " style={{ minHeight: 52 }}>
        <div>
          {pathname !== '/' && (
            <Link
              to="/"
              className="inline-flex items-center gap-2 text-lg no-underline"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2.5}
                  d="M10 19l-7-7m0 0l7-7m-7 7h18"
                />
              </svg>
              MultiMedia Projekte
            </Link>
          )}
          {' '}
          {up && (
            <Link
              to={'/' + up.slug}
              className="inline-flex items-center gap-2 text-lg no-underline"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2.5}
                  d="M10 19l-7-7m0 0l7-7m-7 7h18"
                />
              </svg>
              {up.frontmatter.title}
            </Link>
          )}
        </div>
        <div className="flex-shrink-0 pr-4 md:hidden">
          <button
            type="button"
            className="sticky block text-gray-600 focus:outline-none focus:text-gray-900"
            aria-label="Menu"
            onClick={() => toggleExpansion(!isExpanded)}
          >
            <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
              <path
                d="M3 6C3 5.44772 3.44772 5 4 5H20C20.5523 5 21 5.44772 21 6C21 6.55228 20.5523 7 20 7H4C3.44772 7 3 6.55228 3 6Z"
              />
              <path
                d="M3 12C3 11.4477 3.44772 11 4 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H4C3.44772 13 3 12.5523 3 12Z"
              />
              <path
                d="M4 17C3.44772 17 3 17.4477 3 18C3 18.5523 3.44772 19 4 19H20C20.5523 19 21 18.5523 21 18C21 17.4477 20.5523 17 20 17H4Z"
              />
            </svg>
          </button>
        </div>
      </header>
      <main className={`min-h-screen h-full py-8 ${className} ${ isExpanded ? `expanded-true` : `expanded-false` } `}>
        {children}
      </main>
      <footer
        className={`py-10 border-t-2 border-gray-500 dark:border-gray-100 flex justify-between w-full ${footerClassName}`}
      >
        <p>
          website created by <a href="https://twitter.com/motcodes">@motcodes</a>, content by Brigitte Jellinek
        </p>
      </footer>
    </div>
  );
}

export default Layout;
